<template>
  <form-transformations />
</template>

<script>
  import FormTransformations from '@/components/forms/form-transformations'

  export default {
    name: 'edit',
    components: { FormTransformations },

    beforeRouteLeave (to, from, next) {
      next(true)
    },
  }
</script>

<style scoped>

</style>
